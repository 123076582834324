import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Cookies from 'js-cookie';
import pinsicon from '../image/ion_pin.png';
import board_border_outer from '../image/board_border-outer.png';
import Schedule_lineicon from '../image/schedule-lineicon.png';
import Uil_setting from '../image/uil_setting.png';
import Free_pinterest from '../image/free_pinterest.png';
import Template_soutlined from '../image/templates-outlined.png';

// import Boardicon from"../image/";

const { decrypt } = require('../helpers/encryption-decryption');
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const { SubMenu } = Menu;
var UserRole = [];

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const [userRole, setUserRole] = useState();
  useEffect(() => {
    var enc_userDetail = Cookies.get('UserDetail');
    var response = decrypt(enc_userDetail);
    console.log(response);
    if (response?.login == true) {
      const UserInfo = response?.sessdata?.user?.[0];
      const GetRole = UserInfo?.user_role?.toUpperCase();
      setUserRole(GetRole);
      const modules = UserInfo?.permissions?.[GetRole].MODULES;
      UserRole = modules;
    }
  }, []);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      gutter={30}
      className="custmblock"
    >
      <>
        {UserRole['POSTS'] !== undefined ? (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/users/add-pins`}>
                  <img src={pinsicon} />
                  {/* <i class="fa fa-thumb-tack" style={{ fontSize: '20px', rotate: '30deg' }}></i> */}
                </NavLink>
              )
            }
            key="pins"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/users/add-pins`}>
              Pins
            </NavLink>
          </Menu.Item>
        ) : (
          ''
        )}

        {UserRole['POSTS'] !== undefined ? (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/users/customer-list`}>
                  <FeatherIcon icon="list" />
                </NavLink>
              )
            }
            key="customerslist"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/users/customer-list`}>
              Customers
            </NavLink>
          </Menu.Item>
        ) : (
          ''
        )}

        {UserRole['POSTS'] !== undefined ? (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/users/Board`}>
                  {/* <FeatherIcon icon="layers" /> */}
                  <img src={board_border_outer} />
                </NavLink>
              )
            }
            key="trelloboard"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/users/Board`}>
              Board
            </NavLink>
            {/* <NavLink onClick={toggleCollapsed} to={`${path}/users/PinterestBoard`}>
                  Board
              </NavLink> */}
          </Menu.Item>
        ) : (
          ''
        )}

        {UserRole['POSTS'] !== undefined ? (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/users/Schedule`}>
                  <img src={Schedule_lineicon} />
                  {/* <i class="fa fa-calendar" aria-hidden="true"></i> */}
                </NavLink>
              )
            }
            key="schedule"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/users/Schedule`}>
              Schedule
            </NavLink>
            {/* <NavLink onClick={toggleCollapsed} to={`${path}/users/PinterestBoard`}>
                  Board
              </NavLink> */}
          </Menu.Item>
        ) : (
          ''
        )}
        {/* {UserRole['POSTS'] !== undefined ? (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/users/workflow`}>
                  <i class="fa fa-gears" aria-hidden="true"></i>
                </NavLink>
              )
            }
            key="workflow"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/users/workflow`}>
              WorkFlow
            </NavLink>
          </Menu.Item>
        ) : (
          ''
        )} */}

        {(UserRole['SOCIAL_ACCOUNTS'] !== undefined && userRole == 'SUPER_ADMIN') || userRole == 'CLIENT' ? (
          <SubMenu key="Settings" icon={!topMenu && <img src={Uil_setting} />} title="Settings">
            {userRole == 'CLIENT' || userRole == 'SUPER_ADMIN' ? (
              <>
                <Menu.Item key="defaultimage">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/default-image`}>
                    Default
                  </NavLink>
                </Menu.Item>
              </>
            ) : (
              ''
            )}
            {userRole == 'SUPER_ADMIN' ? (
              <>
                <Menu.Item key="pinboard">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/Pinterest`}>
                    Pinterest
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="config">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/configuration`}>
                    Configuration
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="chatgpt">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/chatgpt`}>
                    Chat Gpt
                  </NavLink>
                </Menu.Item>
              </>
            ) : (
              ''
            )}
          </SubMenu>
        ) : (
          ''
        )}

        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/users/social_accounts`}>
                <img src={Free_pinterest} />
                {/* <i class="fa fa-pinterest" style={{ color: '#ff0000' }}></i> */}
              </NavLink>
            )
          }
          key="social_accounts2"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/users/social_accounts`}>
            Pinterest
          </NavLink>
        </Menu.Item>
        {UserRole['POSTS'] !== undefined ? (
          <Menu.Item
            className=""
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/users/templates`}>
                  <img src={Template_soutlined} />
                  {/* <FeatherIcon icon="layout" /> */}
                </NavLink>
              )
            }
            key="templates"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/users/templates`}>
              Templates
            </NavLink>
            {/* <NavLink onClick={toggleCollapsed} to={`${path}/users/PinterestBoard`}>
                  Board
              </NavLink> */}
          </Menu.Item>
        ) : (
          ''
        )}
      </>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
