import React, { useEffect, useState } from 'react';
import Axios from 'axios';

import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Admin from './routes/admin'; //calling admin

import Auth from './routes/auth'; //calling auth
import Exam from './routes/ExamURL';
//calling auth
import './static/css/style.css';
import config from './config/config'; //config-------------------------------1
import store from './redux/store'; //calling store---------------------------2
import ProtectedRoute from './components/utilities/protectedRoute';
import Cookies from 'js-cookie';
Axios.defaults.withCredentials = true;
// //==================================================================================================================

const { theme } = config;
var ExamUrl;
var ExamURL1;
const ProviderConfig = () => {
  var enc_userDetail = Cookies.get('UserDetail');
  var access_token = Cookies.get('access_token');
  const history = useHistory();
  // console.log('1-1 React- i am in App.js ProviderConfig()');
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });
  // console.log(isLoggedIn);

  const [path, setPath] = useState(window.location.pathname);
  // console.log(path);
  var sessionID = Cookies.get('sessionID');
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      //if unmounted true
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  useEffect(() => {
    if (sessionID == undefined) {
      var create_session = randomstring(32);
      Cookies.set('sessionID', create_session);
      // SetAccessTokenCookies(create_session)
    } else {
      // console.log("already in cookies");
      sessionID = Cookies.get('sessionID');
    }
  }, []);
  function randomstring(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  console.log(window.location.pathname, 'window.location.pathname');
  const splitedURL = window.location.pathname.split('/');
  var PathArray = [
    'chatboard',
    'login',
    '',
    'editapplication',
    'leadsai',
    'application_ai',
    'sms',
    'forgotPassword',
    'oneTimePassword',
    'resetPassword',
    'qr',
    'register',
    'privacy-policy',
    'cookie-policy',
    'terms&conditions',
    'manage-cookies',
    'contact-us',
    'pricing',
    'about',
  ];
  if (PathArray.includes(splitedURL?.[1])) {
    ExamUrl = 'home';
  }

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        {ExamUrl != 'home' ? (
          <Router basename={process.env.PUBLIC_URL}>
            {!isLoggedIn ? (
              <Route path="/" component={ExamURL1 == `exam` ? Exam : Auth} />
            ) : (
              <ProtectedRoute path="/admin" component={Admin} />
            )}
            {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
              <>
                <Route path="/" component={Auth} />
                <Route path="/admin" component={Admin} />
                {/* <Redirect to="/dashboard" /> */}
              </>
            )}
          </Router>
        ) : (
          <Router basename={process.env.PUBLIC_URL}>
            <Route path="/" component={Exam} />
          </Router>
        )}
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
