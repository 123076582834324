import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const OneTimePass = lazy(() => import('../container/profile/authentication/overview/OneTimePassword'));
const ResetPass = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const login = lazy(() => import('./../container/profile/authentication/overview/SignIn'));
const HomePage = lazy(() => import('./../container/pages/HomePage'));
const PrivacyPolicy = lazy(() => import('../container/profile/authentication/overview/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('../container/profile/authentication/overview/CookiePolicy'));
const Termcondation = lazy(() => import('../container/profile/authentication/overview/Termcondation'));
const ManageCookies = lazy(() => import('../container/profile/authentication/overview/ManageCookies'));
const ContactUs = lazy(() => import('../container/profile/authentication/overview/ContactUs'));
const Pricing = lazy(() => import('../container/profile/authentication/overview/pricing'));
const About = lazy(() => import('../container/profile/authentication/overview/About'));
console.log('AUTH');
const NotFound = () => {
  console.log('not found');
  return <Redirect to="/" />;
};
// const RedirectToDashboard = ()=>{

// }
const FrontendRoutes = () => {
  console.log('frontend route');
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/about" component={About} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/manage-cookies" component={ManageCookies} />
        <Route exact path="/terms&conditions" component={Termcondation} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/cookie-policy" component={CookiePolicy} />
        <Route exact path="/login" component={login} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route exact path="/oneTimePassword" component={OneTimePass} />
        <Route exact path="/resetPassword/:id" component={ResetPass} />
        <Route exact path="/register" component={SignUp} />
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
