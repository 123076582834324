import React, { useState, useCallback } from 'react';
import Axios from 'axios';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row ,Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { login, alreadyloggedin } from '../../../../redux/authentication/actionCreator';
import Heading from '../../../../components/heading/heading';
import bgimage from '../../../../image/backgroundimage.png';
import bgimageside1 from '../../../../image/character-login_image.png';
 import bgimageside2 from '../../../../image/character-login_img-2.png';
const SignIn = () => {
  //  console.log('React-I am in Sign in overview/');
  const history = useHistory(); //for redirects in page
  const dispatch = useDispatch();
  const { isLoading, isLogin, isMessage } = useSelector(state => {
    return {
      isLoading: state.auth.loading,
      isLogin: state.auth.login,
      isMessage: state.auth.message,
      isError: state.auth.error,
    };
  });
  const [form] = Form.useForm(); //form

  const [state, setState] = useState({
    checked: null,
  });

  const [value, setValue] = useState(null);

  const handleLoginChange = e => {
    // console.log('radio checked', e.target.checked);
    setValue(e.target.checked);
    // console.log(value);
  };

  const handleSubmit = useCallback(
    values => {
      //  console.log('Received values of form: ', values);
      var postData = {
        phoneoremailvalue: values.phoneoremail,
        password: values.password,
      };
      dispatch(login(postData));
    },
    [history, dispatch],
  );

  const onChange = checked => {
    setState({ ...state, checked });
  };

  return (
<>
    
      <Row className='login_bg' style={{backgroundImage: `url(${bgimage})`
      ,backgroundSize: 'cover', backgroundPosition: 'top center',    alignItems: "center",justifyContent: "center" , height:"100vh"}}>
        <Col xxl={12} xl={12} lg={12} md={12} xs={24}>
          <div className='side-1-image' >
          <div className='frist-image'><img src={bgimageside2}></img></div>
          <div className='seciond-image'><img src={bgimageside1}></img></div>
          </div>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} xs={24}>
        <Form
          name="login"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          style={{ background: 'white', padding: '10px',
            border:" 1px solid #999999",
            borderradius:"5px",
          }}
          id='login-from-outer-section'
        >
          <Heading as="h3" style={{ fontSize: '22px', fontWeight: 'bold', color:"#00A6DB" ,   }}>
        Login
          </Heading>
          <Form.Item
            name="phoneoremail"
            rules={[
              {
                message: 'Please input your Username',
                required: true,
              },
              { min: 10, message: 'Username must be minimum 10 characters.' },
            ]}
          >
            
            <Input
              style={{
                border: '2px solid #D8D8D8',
              
                borderImage: '#D8D8D8',
                borderImageSlice: '1',
                width: '100%',
                background: '#fff !important',
                backgroundImage: 'unset!important',
              }}
              id='Username-input'
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                message: 'Please input password',
                required: true,
              },
              { min: 8, message: 'minimum 8 characters.' },
            ]}
            //initialValue="password1@"
            //label="Password"
          >
            <Input.Password
              //    placeholder="Password"
              style={{
                border: '2px solid  #D8D8D8',
                width: '100%',
                background: '#fff !important',
                backgroundImage: 'unset!important',
              }}
              id='password-input'
              placeholder="Password"
            />
          </Form.Item>

          <div className="auth-form-action"  style={{
            marginBottom:"0px",
          }}>
            <Form.Item name="checkbox" className='Keepmelogged' id='Keepmelogged-12'>
              <Checkbox onChange={onChange}>Keep me logged in</Checkbox>
            </Form.Item>

            <NavLink style={{
              color:"#e9125b",
            }} className="forgot-pass-link account-section-Sign" to="/forgotPassword">
              Forgot password?
            </NavLink>
          </div>

          <Form.Item >
            <Button id="sumbit-btn-login" className="btn-signin" htmlType="submit" type="primary" size="large">
              {isLoading ? 'Loading...' : 'login'}
            </Button>
          </Form.Item>
          <div className='haveanaccount' style={{
            textAlign:'center',
            fontWeight:'600',
            color:'#585858',
            display:'flex',
            gap:'3px',
            justifyContent:"center",
          }}><h3 className='have_an_account' style={{
            textAlign:'center',
            fontWeight:'400',
            color:'#585858', 
          }}>Don’t have an account?</h3> 
          <a  className="account-section-Signup" style={{
            color:"#d9255b",
           
          }} href='/register'>Sign up</a></div>
        </Form>
        </Col>
      </Row>
      
      </>
  );
};

export default SignIn;
