import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';
import bgimage from '../../../image/backgroundimage.png';
import bgimageside1 from '../../../image/character-login_image.png';
import bgimageside2 from '../../../image/character-login_img-2.png';
import SignIn from './overview/SignIn';
const AuthLayout = WraperContent => {
  return () => {
    return (
      <>
          <WraperContent />
          </>
    );
  };
};

export default AuthLayout;
